import React from "react"
import SinglePost from "../components/SinglePost"
import { graphql } from "gatsby"
import Pager from "../components/Pager"
import PageTitle from "../components/PageTitle"
import PropTypes from "prop-types"
import Seo from "../components/seo"
// import CookieConsent from "react-cookie-consent"
import siteCardImage from "../../static/card.jpg"

export const allPosts = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiPost(
      filter: { active: { eq: true } }
      sort: { fields: published, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        active
        strapiId
        slug
        published(formatString: "MMM D YYYY")
        summary
        content
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`

const BlogTemplate = ({ data, pageContext }) => {
  const posts = data.allStrapiPost.nodes
  const shareCardGenerel = {
    src: siteCardImage,
    width: 1200,
    height: 628,
  }

  return (
    <>
      {/* <CookieConsent
        location="bottom"
        buttonText="موافق"
        cookieName="Bandar Blog Cookies"
        containerClasses="cookies-container"
        contentClasses="cookies-content"
        buttonClasses="cookies-btn"
        expires={150}
        disableStyles={true}
        enableDeclineButton
        declineButtonText="إلغاء"
        declineButtonClasses="cookies-btn-cancel"
        setDeclineCookie
      >
        نحن نستخدم ملفات تعريف الارتباط على موقعنا على الإنترنت لأغراض التعريف
        والتحليل. باستخدام هذا الموقع فإنك توافق على تخزين وإمكانية وصول ملفات
        تعريف الارتباط على جهازك.
      </CookieConsent> */}
      <Seo
        title="مقالات عن التصميم وتطوير الواجهات"
        image={shareCardGenerel}
        url="/blog/"
      />
      <PageTitle title="مقالات" />

      {posts.map((post, index) => {
        return <SinglePost item={post} key={index} />
      })}

      <Pager pageContext={pageContext} />
    </>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogTemplate
